import React from "react"
import Helmet from "react-helmet"

import "../style.css"
import LeafShyp from "../images/leafshyp.png"
import Entheo from "../images/entheo.png"

export default () => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>LeafShyp</title>
        </Helmet>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '2rem',
            }}>
                <img style={{maxWidth: '100%', width: '1000px'}} src={LeafShyp} alt="LeafShyp" />
                <a href="https://entheobrand.com">
                    <img style={{maxWidth: '100%', width: '300px', marginTop: '3rem'}} src={Entheo} alt="Entheo" /> 
                </a>
                <p style={{ maxWidth: '32em', fontFamily: 'sans-serif', fontSize: '1.5em', marginTop: '4rem'}}>
                    California Proposition 64 and the resulting legal climate regarding retail cannabis within the state of California has forced us to shut our doors. We will continue to advocate for patient rights and hope to serve you again in the near future. <br /><br />For inquiries, please contact us at <a href="mailto:info@leafshyp.com">info@leafshyp.com</a>  
                </p>
            </div>
        </div>
    </>
)
